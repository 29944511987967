import styled from "styled-components"
import { Container } from "../styled/Grid"

const Image = styled.div`
  width: 100%;
  height: 200px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  @media ${({ theme }) => theme.breakpoints.md} {
    display: inline-block;
    width: 33%;
    margin-right: 4%;
    height: 140px;
    margin-bottom: 10px;
  }
`

const Content = styled.div`
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.md} {
    display: inline-block;
    width: 63%;
  }
`

const Text = styled.div`
  width: 75%;
  height: 40px;
  background: ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  margin: 10px 0;
  &:first-child {
    width: 100%;
  }
  &:last-child {
    width: 90%;
  }
`

export default function LoadingPlaceholder() {
  return (
    <Container>
      <Image />
      <Content>
        <Text />
        <Text />
        <Text />
      </Content>
    </Container>
  )
}
